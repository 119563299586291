<template>
    <div class="form-publishing">
        <div class="summary">
            <div class="section">{{ `${t('messages.resources.category')}: ${messagesStore.formSummary[FormSteps.Categories].label}` }}</div>
            <div v-if="messagesStore.formSummary[FormSteps.Reason] && messagesStore.formSummary[FormSteps.Reason].label" class="section">
                <span>{{ `${t('messages.resources.reason')}: ${messagesStore.formSummary[FormSteps.Reason].label}` }}</span>
                <span v-if="messagesStore.formSummary[FormSteps.Reason].choice" class="choices-wrap">
                    {{ ` (${getChoiceLabel()})` }}
                </span>
                <div v-if="messagesStore.formSummary[FormSteps.Reason].input" class="inputs-wrap">
                    <span v-for="(input, i) in messagesStore.formSummary[FormSteps.Reason].input" :key="i">
                        <template v-if="input">{{ `${getInputLabel(i)}: ${input}` }}</template>
                    </span>
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <div v-if="messagesStore.formSummary[FormSteps.Reason].message" class="message-wrap">
                    <span>{{ `${t('messages.resources.message')}: ` }}</span>
                    <span v-html="messagesStore.formMessageText"></span>
                </div>
                <!--eslint-enable-->
            </div>
            <div v-if="messagesStore.formSummary[FormSteps.Scope] && messagesStore.formSummary[FormSteps.Scope].scope" class="section">
                {{ `${t('messages.resources.scope')}: ${scopeName}` }}
                <br />
                <div v-if="messagesStore.formSummary[FormSteps.Scope].scope === Scopes.Departures">
                    <div v-for="(group, i) in formatScopes" :key="i" class="with-item-divider">
                        <span>{{ `${group[0].groupName}: ` }}</span>
                        <span v-for="(departure, j) in group" :key="j">{{ `${formatTime(departure.departureTime)}` }}<span>, </span> </span>
                    </div>
                </div>
                <span v-else-if="messagesStore.formSummary[FormSteps.Scope].scope === Scopes.Drivers">
                    {{ `${formatScopes[0].name} (${formatScopes[0].companyNumber})` }}
                </span>
                <span v-else class="with-item-divider">
                    <span v-for="(scope, i) in formatScopes" :key="i">{{ scope.name }}<span>, </span></span>
                </span>
            </div>
        </div>
        <div v-if="messagesStore.formSummary[FormSteps.Reason].pushAvailable">
            <v-checkbox
                v-model="pushNotifications"
                :disabled="editMode"
                :label="t('messages.resources.pushNotifications')"
                color="#00c654"
                data-id="pushNotifications"
            ></v-checkbox>
        </div>
        <div v-if="messagesStore.formSummary[FormSteps.Scope].scope !== Scopes.Departures" class="dates-wrap">
            <div>
                <v-label>{{ t('messages.resources.startPublishing') }}</v-label>
                <v-row>
                    <v-col>
                        <v-menu
                            v-model="startPublish.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            min-width="290px"
                            offset-y
                            transition="scale-transition"
                        >
                            <template #activator="{ props }">
                                <v-text-field
                                    v-model="startPublish.date"
                                    :label="t('messages.resources.date')"
                                    data-id="DateTextField"
                                    prepend-icon="event"
                                    readonly
                                    v-bind="props"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="startPublishPicker"
                                data-id="DatePicker"
                                show-adjacent-months
                                @update:model-value="updateTimeModel"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="startPublish.time"
                            :label="t('messages.resources.time')"
                            type="time"
                            data-id="TimeTextField"
                            prepend-icon="access_time"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </div>
            <div>
                <v-label>{{ t('messages.resources.endPublishing') }}</v-label>
                <v-row>
                    <v-col>
                        <v-menu
                            v-model="endPublish.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            min-width="290px"
                            offset-y
                            transition="scale-transition"
                        >
                            <template #activator="{ props }">
                                <v-text-field
                                    v-model="endPublish.date"
                                    :label="t('messages.resources.date')"
                                    data-id="DateTextField"
                                    prepend-icon="event"
                                    readonly
                                    v-bind="props"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="endPublishPicker"
                                :allowed-dates="allowedPublishDates"
                                data-id="DatePicker"
                                show-adjacent-months
                                @update:model-value="updateTimeModel"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="endPublish.time"
                            :label="t('messages.resources.time')"
                            type="time"
                            data-id="TimeTextField"
                            prepend-icon="access_time"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { DateTimeToUTC, FormatDateISO, DateToUTC, ToLocalDate } from '@/shared/DateFormatHelpers';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { FormSteps, Scopes } from './messagesModels';
import { useMessagesStore } from './messagesStore';

const messagesStore = useMessagesStore();
const { t } = useI18n();
const route = useRoute();

const pushNotifications = ref(false);
const startPublish = ref({
    menu: false,
    menuTime: false,
    date: '',
    time: ''
});
const startPublishDate = computed(() => {
    return { date: startPublish.value.date, time: startPublish.value.time };
});
const startPublishPicker = computed<Date>({
    get: () => {
        return new Date(startPublish.value.date);
    },
    set: (value: Date) => {
        const d = value;
        startPublish.value.date = FormatDateISO(d);
    }
});
const endPublish = ref({
    menu: false,
    menuTime: false,
    date: '',
    time: ''
});
const endPublishDate = computed(() => {
    return { date: endPublish.value.date, time: endPublish.value.time };
});
const endPublishPicker = computed<Date>({
    get: () => {
        return new Date(endPublish.value.date);
    },
    set: (value: Date) => {
        const d = value;
        endPublish.value.date = FormatDateISO(d);
    }
});
const selectedPublishData = ref({
    startPublish: '',
    endPublish: ''
});
const editMode = ref(false);
const scopeName = computed(() => {
    const sn = t(`messages.resources.${messagesStore.formSummary[FormSteps.Scope].scope.toLowerCase()}`);
    return messagesStore.formSummary[FormSteps.Scope].scope === 'Global' ? `${sn}` : `${sn}:`;
});
const formatScopes = computed(() => {
    const scopes = messagesStore.formSummary[FormSteps.Scope].selectedScopes;
    if (messagesStore.formSummary[FormSteps.Scope].scope === Scopes.Departures) {
        return formatDepartureScopes(scopes);
    }

    if (messagesStore.formSummary[FormSteps.Scope].scope === Scopes.Stops) {
        return formatStopScopes(scopes);
    }

    return scopes;
});

if (route.params.id) {
    setupEditingMessage();
} else {
    setupCreatingMessage();
}

watch(startPublishDate, () => {
    updateTimeModel();
});

watch(endPublishDate, () => {
    updateTimeModel();
});

watch(pushNotifications, (val: any) => {
    messagesStore.setFormSummaryByCurrentStep({ ...messagesStore.formSummary[messagesStore.currentStep], pushNotifications: val });
});

function formatDepartureScopes(scopes: any) {
    scopes.map((x: any) => {
        x.groupName = `${t('messages.resources.line')} ${x.lineName} (${x.departure} - ${x.destination}, ${ToLocalDate(
            x.departureTime,
            'date'
        )})`;
    });

    const groups = scopes.reduce(function (groups: any, departure: any) {
        const name = departure.groupName || departure.name;
        groups[name] = groups[name] || [];
        groups[name].push(departure);
        return groups;
    }, {});

    for (const group in groups) {
        groups[group].sort((a: any, b: any) => {
            if (new Date(a.departureTime) > new Date(b.departureTime)) return 1;
            if (new Date(a.departureTime) < new Date(b.departureTime)) return -1;
            return 0;
        });
    }

    return groups;
}

function formatStopScopes(scopes: any) {
    return scopes.map((x: any) => {
        return { name: `${x.fullName} (${x.id})` };
    });
}

function setupEditingMessage() {
    editMode.value = true;
    pushNotifications.value = messagesStore.formSummary[FormSteps.Reason]?.pushNotifications;
    selectedPublishData.value = {
        startPublish: messagesStore.message.startPublish ?? '',
        endPublish: messagesStore.message.endPublish ?? ''
    };

    startPublish.value.date = ToLocalDate(selectedPublishData.value.startPublish, 'ISOdate');
    startPublish.value.time = ToLocalDate(selectedPublishData.value.startPublish, 'time');
    endPublish.value.date = ToLocalDate(selectedPublishData.value.endPublish, 'ISOdate');
    endPublish.value.time = ToLocalDate(selectedPublishData.value.endPublish, 'time');
}

function setupCreatingMessage() {
    if (messagesStore.formSummary[FormSteps.Scope].scope === Scopes.Departures) {
        // For departures scope publishing dates determined by selected departures
        setDeparturesPublishTimes();
    } else {
        setDefaultPublishTimes();
    }
    if (messagesStore.formSummary[FormSteps.Reason].pushAvailable) {
        messagesStore.setFormSummaryByCurrentStep({ ...messagesStore.formSummary[messagesStore.currentStep], pushNotifications: false });
    }
}

function setDeparturesPublishTimes() {
    const scopes = messagesStore.formSummary[FormSteps.Scope].selectedScopes;
    const dates = [] as Array<string>;
    scopes.forEach((scope: any) => {
        dates.push(scope.operatingDay);
    });
    const uniqueDates = Array.from(new Set(dates));
    uniqueDates.sort((one, two) => (one < two ? -1 : 1));

    startPublish.value.date = uniqueDates[0];
    startPublish.value.time = '00:00:00';
    endPublish.value.date = uniqueDates[uniqueDates.length - 1];
    endPublish.value.time = '23:59:59';
    updateTimeModel();
}

function setDefaultPublishTimes() {
    const now = new Date().toISOString();
    const nextWeek = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString();

    startPublish.value.date = ToLocalDate(now, 'ISOdate');
    startPublish.value.time = ToLocalDate(now, 'time');
    endPublish.value.date = ToLocalDate(nextWeek, 'ISOdate');
    endPublish.value.time = ToLocalDate(nextWeek, 'time');
    updateTimeModel();
}

function formatTime(time: string) {
    return ToLocalDate(time, 'time');
}

function getChoiceLabel() {
    const group = messagesStore.formSummary[FormSteps.Reason].choiceTemplates[0].group;
    const choice = messagesStore.formSummary[FormSteps.Reason].choice[group];
    const templates = messagesStore.formSummary[FormSteps.Reason].choiceTemplates;
    const choiceLabel = templates.find((item: any) => {
        return item.name === choice;
    });

    return choiceLabel.label;
}

function getInputLabel(inputName: any) {
    const templates = messagesStore.formSummary[FormSteps.Reason].inputTemplates;
    const inputLabel = templates.find((item: any) => {
        return item.name === inputName;
    });
    return inputLabel.label;
}

function allowedPublishDates(val: any) {
    return DateToUTC(val) >= DateToUTC(startPublish.value.date);
}

function updateTimeModel() {
    startPublish.value.menu = false;
    endPublish.value.menu = false;

    if (startPublish.value.date && startPublish.value.time) {
        selectedPublishData.value.startPublish = DateTimeToUTC(startPublish.value.date, startPublish.value.time).toISOString();
    }
    if (endPublish.value.date && endPublish.value.time) {
        selectedPublishData.value.endPublish = DateTimeToUTC(endPublish.value.date, endPublish.value.time).toISOString();
    }
    if (messagesStore.formSummary.length >= FormSteps.Publishing) {
        // update only when previous steps are filled out
        messagesStore.setFormSummaryByStep({
            item: { ...messagesStore.formSummary[FormSteps.Publishing], ...selectedPublishData.value },
            step: FormSteps.Publishing
        });
    }
}
</script>

<style lang="scss" scoped>
.summary {
    font-size: 20px;

    .section {
        margin-top: 24px;

        .message-wrap {
            display: flex;

            span {
                margin-right: 6px;
            }
        }
    }
}

.with-item-divider span:last-child span {
    display: none;
}

.dates-wrap {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 50% 50%;
}
</style>
