<template>
    <div class="departures-scope">
        <div>
            <v-row class="departures-controls">
                <v-col>
                    <v-menu
                        v-model="departuresMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="auto"
                        offset-y
                        transition="scale-transition"
                    >
                        <template #activator="{ props }">
                            <v-text-field
                                v-model="departuresDay"
                                :label="t('messages.resources.date')"
                                data-id="DateTextField"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="props"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="departuresDayPicker"
                            data-id="DatePicker"
                            show-adjacent-months
                            @update:model-value="departuresMenu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-model="selectedLine"
                        :items="autocompleteLines"
                        :label="t('messages.resources.line')"
                        :placeholder="t('messages.resources.searchLine')"
                        append-inner-icon="mdi-magnify"
                        return-object
                        color="primary"
                        data-id="NameAutoComplete"
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-skeleton-loader
                v-if="departuresLoading"
                :transition-group="'fade-transition'"
                class="departures-loader"
                type="table-tbody"
            ></v-skeleton-loader>
            <v-row v-else class="departure-wrap">
                <v-col v-for="(departure, i) in departures" :key="i" class="text-center" cols="6">
                    <div class="departureTitle">
                        {{ departure[0].destination }}
                    </div>
                    <div class="departureTitle">
                        From
                        {{ departure[0].departure }}
                    </div>
                    <range-selector
                        :day="departuresDay"
                        :deselected="deselectedDeparture"
                        :items="departure"
                        :line="selectedLine?.data"
                        :selected-departures="selectedDeparturesList"
                        @removed="removeDeparture"
                        @selected="addDeparture"
                    />
                </v-col>
            </v-row>
        </div>
        <div class="departure-wrap-selected">
            <selected-departures :selected-departures="selectedDeparturesList" @removed-from-summary="removeDepartureFromSummary" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import RangeSelector from './RangeSelector.vue';
import SelectedDepartures from './SelectedDepartures.vue';
import { FormatDateISO, ToLocalDate } from '@/shared/DateFormatHelpers';
import { DepartureReceiver, FormSteps } from '@/Features/Messages/messagesModels';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { AutocompleteItem } from '@/shared/models';
import { useLinesStore } from '@/Features/Lines/linesStore';
import { Line } from '@/Features/Lines/linesModels';
import { toLineAutocompleteItem } from '@/Features/Lines/lineHelpers';
import { useMessagesStore } from '../messagesStore';

const messagesStore = useMessagesStore();
const linesStore = useLinesStore();
const { t } = useI18n();

const selectedDeparturesList = ref<DepartureReceiver[]>([]);
const departures = ref<any[]>([]);
const selectedLine = ref<AutocompleteItem<Line> | null>(null);
const departuresDay = ref(FormatDateISO(new Date()));
const departuresDayPicker = computed<Date>({
    get: () => {
        return new Date(departuresDay.value);
    },
    set: (value: Date) => {
        const d = value;
        departuresDay.value = FormatDateISO(d);
    }
});
const deselectedDeparture = ref<any>(null);
const departuresMenu = ref(false);
const departuresLoading = ref(false);
const autocompleteLines = computed<AutocompleteItem<Line>[]>(() => linesStore.lines.map(x => toLineAutocompleteItem(x)));

messagesStore.selectedFormItem = [];
if (autocompleteLines.value.length === 0) {
    await linesStore.loadLines();
}

const scopes = messagesStore.formSummary[FormSteps.Scope]?.selectedScopes;
if (scopes?.length) {
    selectedDeparturesList.value = scopes;
}

watch(departuresDay, async () => {
    await loadDeparturesAfterSelection();
});

watch(selectedLine, async () => {
    await loadDeparturesAfterSelection();
});

watch(
    selectedDeparturesList,
    (val: any) => {
        messagesStore.setSummaryScopes(val);
    },
    { deep: true }
);

function getDepartureTime(time: string) {
    return ToLocalDate(time, 'time');
}

function departureExists(array: Array<any>, id: string, date: string) {
    return array.some((el: any) => el.id === id && el.operatingDay === date);
}

async function loadDeparturesAfterSelection() {
    const line = selectedLine.value?.data;
    if (line?.lineNumber) {
        departures.value = [];
        departuresLoading.value = true;

        await messagesStore.loadServiceJourneys({
            date: departuresDay.value,
            lineNumber: line.lineNumber
        });

        const sortedJourneys = messagesStore.serviceJourneys.reduce(function (acc: any, item: any) {
            let key = `${item.departure} ${item.destination}`;
            if (acc[key] || (acc[key] = [])) {
                acc[key].push(item);
                return acc;
            }
        }, {});
        departures.value = Object.values(sortedJourneys);
        departuresLoading.value = false;
    }
}

function addDeparture(trip: any) {
    const time = getDepartureTime(trip.departureAt);
    const date = ToLocalDate(trip.departureAt, 'date');
    const tripName = `${t('messages.resources.line')} ${trip.lineName} (${trip.departure} - ${trip.destination}) ${time}/(${date})`;

    const departure = {
        operatingDay: departuresDay.value,
        departureTime: trip.departureAt,
        id: trip.tripId,
        name: tripName,
        lineName: selectedLine.value?.data.name,
        departure: trip.departure,
        destination: trip.destination,
        companyNumber: trip.companyNumber
    };
    const isInArray = departureExists(selectedDeparturesList.value, departure.id, departure.operatingDay);

    if (!isInArray) {
        const groupName = `${t('messages.resources.line')} ${trip.lineName}, ${trip.destination} - from ${trip.departure} (${
            departuresDay.value
        })`;
        const selectedDeparture = {
            groupName: groupName,
            ...departure
        };
        selectedDeparturesList.value.push(selectedDeparture);
    }
}

function removeDeparture(trip: any) {
    let tripId = trip.tripId || trip.id;
    let index = selectedDeparturesList.value.findIndex((x: any) => x.id === tripId && x.operatingDay === trip.operatingDay);
    if (index !== -1) selectedDeparturesList.value.splice(index, 1);
}

function removeDepartureFromSummary(trip: any) {
    removeDeparture(trip);
    deselectedDeparture.value = trip;
}
</script>

<style lang="scss" scoped>
.departures-scope {
    display: grid;
    grid-template-columns: 70% 30%;
}

.departures-loader,
.departure-wrap {
    max-height: 40vh;
}

.departure-wrap-selected {
    max-height: 40vh;
    border-left: thin rgba(0, 0, 0, 0.2) solid;
    margin-left: 24px;
}

.departure-wrap,
.departure-wrap-selected {
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
}
</style>
