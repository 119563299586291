import { defineStore } from 'pinia';
import { Line } from './linesModels';
import { useCommonStore } from '@/shared/commonStore';
import { useAxios } from '@/shared/axios';
import { LineRels } from './rels';
import { ListResource } from '@/shared/models';

export const useLinesStore = defineStore('lines', {
    state: () => ({
        lines: new Array<Line>(),
        linesWithAll: new Array<Line>()
    }),
    actions: {
        async loadLines() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(LineRels.getLines);

            const response = await axios.requestByLink<ListResource<Line>>(link, {
                params: {
                    includeAll: true
                }
            });
            this.lines = response.data.data.filter(x => x.lineNumber !== '0');
            this.linesWithAll = response.data.data;
        }
    }
});
