import { TransportType } from '@/shared/models';

export interface Line {
    lineNumber: string;
    name: string;
    transportType: TransportType;
}

export const AllLines = {
    lineNumber: '0',
    name: 'All',
    transportType: TransportType.None
} as Line;
